<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <statistics-card-with-images
          class="fill-height"
          :icon="ratingsOptions.icon"
          :avatar="ratingsOptions.avatar"
          :avatar-width="ratingsOptions.avatarWidth"
          :chip-color="ratingsOptions.chipColor"
          :chip-text="ratingsOptions.chipText"
          :statistics="totalAmountList"
          :stat-title="ratingsOptions.statTitle"
        ></statistics-card-with-images>
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-card class="fill-height">
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <div class="d-flex align-center pb-5">
              <v-text-field
                v-model="filter.searchString"
                dense
                outlined
                clearable
                hide-details
                placeholder="Tag suchen"
                class="channel-list-search me-3"
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
      </v-col>



    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card id="channel-list">
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex align-center pb-5">
              <v-btn
                color="secondary"
                outlined
                class="me-3"
                @click="editTag()"
              >
                <v-icon size="30" class="pr-2">{{icons.mdiTagOutline }}</v-icon>
                <span>Tag erstellen</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="listTags"
          :options.sync="options"
          :server-items-length="totalAmountList"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :search="filter.searchString"
          class="text-no-wrap"
          no-data-text="Keine Einträge vorhanden"
          no-results-text="Ihre Suche ergab keinen Treffer"
          :footer-props="{
        itemsPerPageText: 'Anzahl pro Seite',
        itemsPerPageAllText: 'Alle',
        pageText: '{0}-{1} von {2}',
        itemsPerPageOptions: [5,10,25,50,-1]
      }"
        >
          <template
            v-slot:top
          >
            <v-dialog
              v-model="dialogDelete"
              width="500px"
            >
              <v-card>
                <v-card-text
                  class="text-center"
                >
                  Möchten Sie <strong>&nbsp;{{ deleteItem.titel }}&nbsp;</strong> wirklich löschen?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="deleteTagConfirm"
                  >
                    Löschen
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeDelete"
                  >
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>



            <v-dialog
              v-model="dialogEdit"
              width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editItem.titel"
                          label="Titel"
                          dense
                          hide-details
                          outlined
                          :rules="[titleRules]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12">
                        <v-textarea
                          v-model="editItem.memo"
                          label="Memo"
                          auto-grow
                          dense
                          hide-details
                          outlined
                          rows="6"
                          row-height="15"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    :disabled="editValidationError"
                    @click="editTagConfirm()"
                  >
                    Speichern
                  </v-btn>
                  <v-btn
                    color="secondary"
                    outlined
                    @click="closeEdit()"
                  >
                    Abbrechen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


          </template>
          <template #[`item.idNipTag`]="{item}">
            #{{ item.idNipTag }}
          </template>
          <template #[`item.actions`]="{item}">
            <div class="">
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="editTag( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>
              <v-tooltip
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteTag( item )"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Löschen</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiFilePdf, mdiTagOutline,mdiPlus
} from '@mdi/js'
import axios from "axios";
import {getCurrentInstance, ref} from "@vue/composition-api";
import { avatarText } from '@core/utils/filter'
import InlineDatePicker from "@/views/InlineDatePicker.vue";
import router from '@/router'
import StatisticsCardWithImages from "@core/components/statistics-card/StatisticsCardWithImages";

export default {
  name: 'channel-list',
  components: {
    InlineDatePicker,
    StatisticsCardWithImages
  },
  setup() {
    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 1000;

    const ratingsOptions = {
      statTitle: 'Tags',
      statistics: '13k',
      change: '+38%',
      chipText: 'Gesamt',
      chipColor: 'error',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
      icon:mdiTagOutline
    }

    return {
      vm,
      router,
      ratingsOptions
    }
  },
  data() {
    return {
      avatarText:avatarText,
      actions: ['Delete', 'Edit'],
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiFilePdf,
        mdiTagOutline,
        mdiPlus,
      },
      filter: { searchString: '', status: 'offene',datumVon:'',datumBis:''},
      loading: false,
      totalAmountList: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['idNipTag'],
        sortDesc: [true],
      },
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'idNipTag',
        },
        {text: 'Titel', value: 'titel'},
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      listTags: [],
      dialogDelete: false,
      dialogEdit: false,
      deleteItem: {
        idNipTag: 0,
        name: '',
      },
      editIndex: -1,
      editValid : false,
      editItem: {
        idNipTag: 0,
        name: '',
        memo: '',
      },
      defaultItem: {
        idNipTag: 0,
        name: '',
        memo: '',
      },
      editValidationError : true,

    }
  },
  mounted() {
    this.fetchTags()
  },
  watch: {
    options: {
      handler() {
        this.fetchTags()
      },
    },
    filter: {
      handler() {
        this.fetchTags()
      },
      deep: true,
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogEdit (val) {
      val || this.closeEdit()
    },
  },
  computed: {
    formTitle () {
      return this.editIndex === -1 ? 'Tag erstellen' : 'Tag bearbeiten'
    },
  },
  methods: {
    fetchTags() {
      this.loading = true
      axios.post('api/tag/', {
        aktion: 'showOverviewTags',
        filter: this.filter,
        options: encodeURIComponent(JSON.stringify(this.options)),
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.listTags = response.data.tags || []
            this.totalAmountList = parseInt(response.data.total) || 0
            this.loading = false
          } else {
            console.log(response.data.errors)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteTag (item) {
      this.dialogDelete = true
      this.deleteItem = JSON.parse(JSON.stringify(item))
    },
    deleteTagConfirm () {
      axios.post('api/tag/', {
        aktion: 'deleteTag',
        mainData :{idNipTag: this.deleteItem.idNipTag},
      })
        .then((response) => {
          this.vm.$toastr.s(response.data.message.title, response.data.message.text);
          this.fetchTags()
        })
        .catch((error) => {
          console.log(error)
        })

      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
    },
    closeEdit () {
      this.$nextTick(() => {
        this.dialogEdit = false
        this.editIndex = -1
        this.editItem = this.defaultItem
      })
    },
    updateFilter (key,value) {
      this.$nextTick(() => {
        this.filter[key] = value;
      })
    },





    editTag (item) {
      this.editValidationError = false
      this.dialogEdit = true
      if(item) {
        this.editIndex = this.listTags.indexOf(item)
        this.editItem = Object.assign({}, item)
      }
    },
    titleRules (v) {
      this.editValidationError = true
      if(!v) {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      else if((v || '').length < 2) {
        return 'Der Titel muss mindetens 2 Zeichen besitzen.';
      }
      else if((v || '').length > 45) {
        return 'Der Titel kann maximal 45 Zeichen lang sein.';
      }

      this.editValidationError = false
      return true
    },

    editTagConfirm () {
      axios.post('api/tag/', {
        aktion: this.editIndex === -1 ? 'createTagCheck' : 'editTagCheck',
        mainData: this.editItem,
      })
        .then((response) => {
          if (response.data.valid === true) {
            this.vm.$toastr.s(response.data.message.title, response.data.message.text);
            this.fetchTags()
          }
          else {
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              this.vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          this.closeEdit()
        })
        .catch((error) => {
          console.log(error)
        })
    },

  }
}
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: normal;
}
#channel-list {
  .channel-list-search {
    max-width: 10.625rem;
  }
}
</style>
